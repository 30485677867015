import React from 'react';
import Layout from '../components/layout/Layout';
import css from 'styled-components';

const Wrap = css.div`
  width: 1200px;
  margin: 60px auto;
  h1{
    text-align: center;
    font-weight: 500;
    font-size: 40px;
    line-height: 120%;
    margin-bottom: 50px;
  }
  h2{
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    margin: 0 0 10px;
  }
  p{
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    margin: 30px 0;
  }
`;

const Article = () => {
  return (
    <Layout>
      <Wrap>
        <h1>区块链版权有什么优势？</h1>
        <h2>1、版权登记成本低</h2>
        <p>
          传统版权登记价格高，流程长，自动化不足，需要耗费人工，时间成本长。而使用区块链后，自动标记了藏品创建者和所有者，基于区块链不可篡改特性，保证艺术品的唯一性，能够在创建和交易后第一时间进行版权登记，降低了整体的确权成本。
        </p>
        <h2>2、追溯成本低</h2>
        <p>
          传统藏品所有权非公开透明，追溯成本高。而区块链上进行登记的艺术藏品，不可篡改，公开透明，可追溯。更利于查询和追溯
        </p>
        <h2>3、创作者持续收益</h2>
        <p>
          传统藏品在售卖后，创作者将无法再次获取二次收益，创作者无法获得后续的商业权益。而区块链通过智能合约技术，通过设置合理的版税，能够让创作者在后续的持续交易时获得分成，从而进一步提升创作者创作的积极性。
        </p>
      </Wrap>
    </Layout>
  );
};

export default Article;
